import { Box, Button } from '@chakra-ui/react';
import React from 'react';
import { useQuery } from 'react-query';
import { Search } from './Search';
import { useHistory } from 'react-router-dom';
import { Place, PlacesClient } from '../ApiClients';

const placesClient = new PlacesClient();

export const Home: React.FC = () => {
  const history = useHistory();

  const { data: places } = useQuery<Place[]>(
    'places',
    () => placesClient.get(),
    {
      enabled: true,
    }
  );

  const handleSearch = (search: string, name = '') => {
    const latSearch = search.split(',')[0].replace(' ', '');
    const longSearch = search.split(',')[1].replace(' ', '');

    const params = new URLSearchParams();
    params.append('lat', latSearch);
    params.append('long', longSearch);
    if (name !== '') params.append('name', name);
    history.push({ pathname: '/forecast', search: params.toString() });
  };

  return (
    <Box>
      <Box bgColor='white' borderRadius='md' p={1}>
        <Box maxH={72} overflow='scroll' overflowX='hidden' p={4}>
          {places &&
            places.map((p, i) => (
              <Box key={i} mb={4}>
                <Button
                  w='100%'
                  onClick={() => handleSearch(`${p.lat}, ${p.lon}`, p.name)}
                >
                  {p.name}
                </Button>
              </Box>
            ))}
        </Box>
      </Box>

      <Box mt={6} bgColor='white' borderRadius='md'>
        <Search handleSearch={handleSearch} />
      </Box>
    </Box>
  );
};
