import React from "react";
import { Route } from "react-router-dom";
import { Home } from "./components/Home";
import { BrowserRouter as Router } from "react-router-dom";
import { Box, Center, Link, Text } from "@chakra-ui/react";
import { Forecast } from "./components/Forecast";

export const App: React.FC = () => {
  return (
    <Box bgColor="#AED8E5" h="100vh" w="100vw">
      <Box w="90vW" margin="auto" pt={6} maxW="800px">
        <Router>
          <Route exact path="/" component={Home} />
          <Route exact path="/forecast" component={Forecast} />
        </Router>
      </Box>
      <Center w="100%" position="fixed" bottom="0" h={10}>
        <Text mr={1}>weather data:</Text>
        <Link href="https://www.met.no/">met.no</Link>
      </Center>
    </Box>
  );
};
