import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { Box, Text } from '@chakra-ui/layout';
import {
  Collapse,
  Flex,
  Heading,
  IconButton,
  Progress,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { PowderForecast } from '../ApiClients';

interface Props {
  forecasts: PowderForecast[];
}

export const PowForecasts: React.FC<Props> = ({ forecasts }) => {
  return (
    <Box boxShadow='lg' p='6' rounded='md' bgColor='white'>
      {forecasts.map((f) => (
        <Box key={f.period} mb={6}>
          <PowForecast f={f} />
        </Box>
      ))}
    </Box>
  );
};

interface PowForecastProps {
  f: PowderForecast;
}

const PowForecast: React.FC<PowForecastProps> = ({ f }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Heading as='h2'>{f.period}</Heading>
      <Box>{f.periodRange}</Box>
      <Box>
        <Flex>
          <Heading as='h3' size='md' mr={2}>
            Total score ({f.totalScore})
          </Heading>
          <IconButton
            aria-label='What does this score mean?'
            icon={<QuestionOutlineIcon />}
            variant='unstyled'
            h='100%'
            minW={6}
            onClick={() => setIsOpen((prev) => !prev)}
          />
        </Flex>
        <Collapse in={isOpen} animateOpacity>
          <Text>{getScoreText(f.totalScore)}</Text>
        </Collapse>
        <Progress
          mt={2}
          colorScheme={getScoreColorScheme(f.totalScore)}
          value={f.totalScore}
          min={0}
          max={10}
        />
      </Box>
    </>
  );
};

const getScoreText = (score: number) => {
  let scoreText = `A score of ${score} is considered `;
  if (score <= 5) {
    scoreText += 'BAD.';
  } else if (score > 5 && score < 7) {
    scoreText += 'OK.';
  } else {
    scoreText += 'GOOD.';
  }
  scoreText +=
    ' Anything above 7 should have you running to the mountains with the fattest of skis!';
  return scoreText;
};

const getScoreColorScheme = (score: number) => {
  if (score <= 5) {
    return 'red';
  } else if (score > 5 && score < 7) {
    return 'purple';
  } else {
    return 'cyan';
  }
};
