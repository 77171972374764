import { Box } from "@chakra-ui/layout";
import { Input, Button, Text } from "@chakra-ui/react";
import React, { useState } from "react";

interface Props {
  handleSearch: (search: string) => void;
}

export const Search: React.FC<Props> = ({ handleSearch }) => {
  const [searchInput, setSearchInput] = useState("");

  return (
    <Box boxShadow="lg" p="6" rounded="md">
      <Text mb="8px">Latitude & Longitude</Text>
      <Input
        type="text"
        placeholder="59.993, 10.653"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <Box h={4} />
      <Button colorScheme="cyan" onClick={() => handleSearch(searchInput)}>
        Search
      </Button>
    </Box>
  );
};
