import { Button } from '@chakra-ui/button';
import { Box, Center, Heading } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { PowderAlertClient, PowderForecast } from '../ApiClients';
import { PowForecasts } from './PowForecasts';

const powderAlertClient = new PowderAlertClient();

export const Forecast: React.FC = () => {
  const history = useHistory();
  let query = useLocationQuery();

  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');

  const latParam = query.get('lat');
  const longParam = query.get('long');
  const name = query.get('name');

  const powForecast = useQuery<PowderForecast[]>(
    [`${lat}${long}`],
    () => powderAlertClient.get({ Latitude: lat, Longitude: long }),
    {
      enabled: !!lat && !!long,
    }
  );

  useEffect(() => {
    if (!latParam || !longParam) history.push('/');
    else {
      setLat(latParam);
      setLong(longParam);
    }
  }, [latParam, longParam, history]);

  return (
    <Box>
      {powForecast.data ? (
        <>
          {name && (
            <Box
              boxShadow='lg'
              p={3}
              pl={6}
              rounded='md'
              bgColor='white'
              mb={1}
            >
              <Heading>{name}</Heading>
            </Box>
          )}
          <PowForecasts forecasts={powForecast.data} />
          <Box bgColor='white' borderRadius='md' mt={6} p={5}>
            <Button colorScheme='cyan' onClick={() => history.push('/')}>
              New search
            </Button>
          </Box>
        </>
      ) : (
        <Center mt={6}>
          <Spinner size='xl' />
        </Center>
      )}
    </Box>
  );
};

function useLocationQuery() {
  return new URLSearchParams(useLocation().search);
}
